@import '../../styles/vars.scss';

.resume {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bad-load {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      margin: 10px;
    }

    img {
      max-width: 150px;
    }
  }
}
