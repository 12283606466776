@import '../../styles/vars.scss';

.about {
  min-height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__main {
    @media (max-width: 1000px) {
      width: 100%;
      padding: 25px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (min-width: 1000px) {
      width: 75%;
      padding: 100px 60px;
      display: flex;
    }

    &__pic-container {
      @media (max-width: 1000px) {
        width: 80%;
        &__pic {
          max-width: 250px;
          max-height: 500px;
          border-radius: 10px;
          border: 2px solid var(--color-gray);
        }
      }

      @media (min-width: 1000px) {
        width: 40%;
        min-height: 100%;
        display: flex;
        justify-content: flex-end;
        &__pic {
          max-width: 300px;
          max-height: 600px;
          border-radius: 10px;
          border: 2px solid var(--color-gray);
        }
      }
    }

    &__info {
      @media (max-width: 1000px) {
        width: 90%;
        display: flex;
        flex-direction: column-reverse;

        &-about {
          display: flex;
          flex-direction: column;

          &-name {
            font-size: 24pt;
            font-weight: bold;
          }

          &-tagline {
            font-size: 12pt;
            font-weight: bold;
          }

          &-description {
            font-size: 12pt;
            margin-top: 20px;
          }

          .about-item {
            margin-top: 5px;
          }
        }

        &-contact {
          margin-top: 40px;
          margin-bottom: 20px;
          display: flex;
          justify-content: center;

          &-btn {
            font-family: sans-serif;
            background-color: var(--color-teal);
            color: var(--color-white);
            padding: 10px 20px;
            font-size: 12pt;
            border: 2px solid var(--color-gray);
            border-radius: 5px;

            &:hover {
              cursor: pointer;
              background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
            }
          }
        }
      }

      @media (min-width: 1000px) {
        width: 60%;
        min-height: 100%;
        padding: 20px;
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        &-about {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          &-name {
            font-size: 32pt;
            font-weight: bold;
          }

          &-tagline {
            font-size: 14pt;
            font-weight: bold;
          }

          &-description {
            font-size: 12pt;
            margin-top: 40px;
            text-align: start;
          }

          .about-item {
            margin-top: 5px;
          }
        }

        &-contact {
          margin-top: 20px;
          height: 100%;
          display: flex;
          align-items: center;

          &-btn {
            font-family: sans-serif;
            background-color: var(--color-teal);
            color: var(--color-white);
            padding: 20px 40px;
            font-size: 18pt;
            border: 2px solid var(--color-gray);
            border-radius: 5px;

            &:hover {
              cursor: pointer;
              background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
            }
          }
        }
      }
    }
  }
}
