@import '../../styles/vars.scss';

.nav-bar {
  width: 100%;
  background-color: var(--color-black);
  display: flex;
  justify-content: center;
  @media (max-width: 1000px) {
    font-size: 10pt;
  }
  @media (min-width: 1000px) {
    font-size: 12pt;
  }

  &__item {
    color: var(--color-white);
    @media (max-width: 1000px) {
      padding: 10px 20px;
    }
    @media (min-width: 1000px) {
      padding: 15px 30px;
    }
    font-weight: bold;

    &:hover {
      background-image: linear-gradient(rgba(255, 255, 255, 0.1) 0 0);
      cursor: pointer;
    }
  }
}
